
// @codekit-append "../node_modules/foundation-sites/dist/js/foundation.min.js";
// @codekit-append "../bower_components/slick-carousel/slick/slick.min.js";
// @codekit-append "../bower_components/magnific-popup/dist/jquery.magnific-popup.min.js";
// @codekit-append "jquery.inview.min.js";
// @codekit-append "jquery.parallax-scroll.js";



jQuery(document).scroll(function() {
  var y = jQuery(this).scrollTop();
  if (y > 10) {
    jQuery('header').addClass("scroll");
    jQuery('main').addClass("scrollerm");

  } else {
    jQuery('header').removeClass("scroll");
    jQuery('main').removeClass("scrollerm");
  }
});





jQuery(document).ready(function() {

  jQuery(document).on("click", "#newsletter_email, #newsletter_fname, #newsletter_lname", function() {
    jQuery(".datenschutz-container").css({"opacity": 1});
  });
  jQuery(document).on("keyup", "#newsletter_email, #newsletter_fname, #newsletter_lname", function() {
    jQuery(".datenschutz-container").css({"opacity": 1});
  });

  function readCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==" ") c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
  }

  var cookiehinweis = readCookie("cookiekh");
  if (cookiehinweis === null || cookiehinweis <= 0) {
      jQuery(".cookie").fadeIn();
  }
  jQuery(document).on("click", ".cookie, .close-cookie-button", function(){
      jQuery(".cookie").fadeOut();
      var theDate = new Date();
      var oneYearLater = new Date( theDate.getTime() + 31536000000 );
      var expiryDate = oneYearLater.toGMTString();
      document.cookie = "cookiekh=1;expires="+expiryDate+";path=/;"; // secure;
  });

  jQuery("body").mousemove(function(e) {

    var windowwidth = jQuery(window).width() - 330;

    if(e.pageX <= 330) {
      jQuery(".hoverelem--1").addClass("act");
    }
    if(e.pageX >= 330) {
      jQuery(".hoverelem--1").removeClass("act");
    }
    if(e.pageX >= windowwidth) {
      jQuery(".hoverelem--2").addClass("act");
    }
    if(e.pageX <= windowwidth) {
      jQuery(".hoverelem--2").removeClass("act");
    }
  })

  jQuery(document).on("mouseleave", "header", function() {
    var windowwidth = jQuery(window).width();

      if(jQuery(".hidden-content").is(":visible")) { // Nur schließen, wenn bereits geöffnet

        if(windowwidth > 667) {
          jQuery(".hidden-content").slideUp(300);
          jQuery(".close-menu-icon").fadeOut(300);
          jQuery(".breadcrumb, .sidebar-left").fadeIn(300);
        }

      }
  });

  jQuery('.slideshow').magnificPopup({
    type:'inline',
    midClick: true,
    callbacks: {
      open: function() {

        jQuery('.slideshowelems').slick('refresh');
      }
    }
  });

  jQuery(document).on("click", ".slideshow--click-l", function(e) {
  	e.preventDefault();
    jQuery('.slideshowelems').slick('slickPrev');
  });

  jQuery(document).on("click", ".slideshow--click-r", function(e) {
  	e.preventDefault();
    jQuery('.slideshowelems').slick('slickNext');
  });


  jQuery('.slideshowelems').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          dots: false
        }
      },
      {
        breakpoint: 667,
        settings: {
          slidesToShow: 1,
          dots: false
        }
      }
    ]
  });


  jQuery(".menu-button").click(function(e) {
    e.preventDefault();

    jQuery(".menu-button").toggleClass("is-active");
    //jQuery(".menu").slideToggle();
    jQuery(".hidden-content").toggleClass("active");

  });

  jQuery(document).foundation();

  /* SVG Grafiken in <img> Tags in "echte" SVG umwandeln, wenn die Klasse "svg" vorhanden ist */
  convertSvg();

  /* Klick auf "Nach oben" im Footer, um die Seite wieder nach oben zu scrollen */
  jQuery(document).on("click", ".to-top", function() {
  	jQuery('html, body').animate({
  		scrollTop: 0
  	}, 'slow');
  });

  jQuery(document).on("click", ".menuhead", function(e) {
  	e.preventDefault();
    jQuery(this).toggleClass("active");
    jQuery(this).next().find("ul").slideToggle();
  });








  jQuery('.animate--in').on('inview', function(event, isInView) {
    if (isInView) {
      jQuery(this).delay(700).addClass("active");
    } else {
    }
  });

	/* Top-Navigation öffnen */
	jQuery(document).on("mouseover", ".main-link", function() {
      var thisShow = jQuery(this).attr("data-show");
			jQuery(".hidden-content").slideDown(300);
			jQuery(".close-menu-icon").fadeIn(300);
			jQuery(".breadcrumb").fadeOut(300);

      jQuery("div."+thisShow).fadeIn();
	});

  var touchCount = 0;
  jQuery(document).on("touchend", ".main-link", function(e) {

      if(!jQuery(".hidden-content").is(":visible") && touchCount == 0) {

          e.preventDefault();
          touchCount++;
          jQuery(".hidden-content").slideDown(300);
          jQuery(".close-menu-icon").fadeIn(300);
          jQuery(".breadcrumb, .sidebar-left").fadeOut(300);
          jQuery(".contact-link").addClass("visible");

      }
  });

	/* Top-Navigation schließen */
	jQuery(document).on("click", ".close-menu-icon", function() {
		if(jQuery(".hidden-content").is(":visible")) { // Nur schließen, wenn bereits geöffnet
			jQuery(".hidden-content").slideUp(300);
			jQuery(".close-menu-icon").fadeOut(300);
			jQuery(".breadcrumb").fadeIn(300);
		}
	});

	/* Slider Startseite */
	if(jQuery(".modul--heroslider-slider").length) {
		jQuery('.modul--heroslider-slider').slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: false,
			autoplay: true,
      appendDots: jQuery("div.dots"),
			autoplaySpeed: 7000,
			fade: true,
  			cssEase: 'linear'
		  });
	}

  if(jQuery(".slideshowsmall").length) {
    jQuery(".slideshowsmall").each(function() {

      jQuery(this).slick({
  			infinite: true,
  			slidesToShow: 1,
  			slidesToScroll: 1,
  			dots: false,
  			arrows: true,
  			autoplaySpeed: 7000
      });

    })

	}




});

function convertSvg() {
	jQuery('img.svg').each(function(){
		var $img = jQuery(this);
		var imgID = $img.attr('id');
		var imgClass = $img.attr('class');
		var imgURL = $img.attr('src');
		jQuery.get(imgURL, function(data) {
			var $svg = jQuery(data).find('svg');
			if(typeof imgID !== 'undefined') {
				$svg = $svg.attr('id', imgID);
			}
			if(typeof imgClass !== 'undefined') {
				$svg = $svg.attr('class', imgClass+' replaced-svg');
			}
			$svg = $svg.removeAttr('xmlns:a');
			$img.replaceWith($svg);
		}, 'xml');
	});
}
